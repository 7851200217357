<template>
  <div class="main">
    <div class="wating">
      <div class="left">
        <div class="left_title">
          <!-- <i class="el-icon-office-building left_icon"></i> -->
          <div class="left_icon"></div>
          <!-- 广东政沣云计算有限公司 -->
          {{  mainName }}
        </div>
        <div class="left_loading">
          <!-- <div class="loading_img">正在分析中...</div> -->
        </div>
      </div>
      <div class="right">
        <div class="right_header">
          <!-- <i class="el-icon-folder"></i> -->
          <div class="right_header_icon"></div>
          系统正在分析中，请稍后...
        </div>
        <div class="right_content">
          <ul class="content_ul">
            <li
              v-for="(item, index) in list"
              :key="index"
              class="content_li"
            >
              <transition name="el-fade-in-linear">
                <div v-show="item.show" class="content_title">
                  <div class="title_circle"></div>
                  <div class="title_txt">{{item.title}}</div>
                  <div class="title_line"></div>
                  <div class="title_icon">
                    <i :class="map[item.status].icon" :style="{ color: map[item.status].color }"></i>
                  </div>
                </div>
              </transition>
              <div
                v-for="(sub, ind) in item.children"
                :key="ind"
                class="sub_ul"
              >
                <el-collapse-transition>
                  <div v-show="sub.show">{{ sub.subTitle }}</div>
                </el-collapse-transition>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMatch } from '@/api/simple_matching/index';
import { mapMutations, mapState } from 'vuex';
import { getMemberId } from '@/assets/public/utils/token';

export default {
  data () {
    return {
      list: [
        {
          title: '基本情况',
          children: [
            { subTitle: '注册地址', show: false },
            { subTitle: '企业类型', show: false },
            { subTitle: '企业规模', show: false },
            { subTitle: '所属行业', show: false },
            { subTitle: '学历', show: false },
            { subTitle: '职称', show: false }
          ],
          status: 0,
          show: false
        },
        {
          title: '经营概况',
          children: [
            { subTitle: '上市', show: false },
            { subTitle: '产学研合作', show: false },
            { subTitle: '参展参会', show: false }
          ],
          status: 0,
          show: false
        },
        {
          title: '知识产权',
          children: [
            { subTitle: '实用型专利', show: false },
            { subTitle: '发明专利', show: false },
            { subTitle: '外观设计专利', show: false },
            { subTitle: '软件著作权', show: false },
            { subTitle: '作品著作权', show: false },
            { subTitle: '商标', show: false }
          ],
          status: 0,
          show: false
        },
        {
          title: '资质认证',
          children: [],
          status: 0,
          show: false
        }
      ],
      map: {
        0: { icon: 'el-icon-loading', color: '' },
        1: { icon: 'el-icon-check', color: '#156ED0' },
        2: { icon: 'el-icon-question', color: '#F7B320' }
      },
      basicData: {},
      isOver: true,
      timerOne: null,
      timerTwo: null,
      timerThree: null,
      mainName: JSON.parse(localStorage.getItem("mainName")),
    };
  },
  computed: {
    ...mapState('simpleMatching', [ 'companyName' ]),
    userId () {
      return getMemberId();
    },
    ...mapState({
      isVip: (state) => state.login.isVip
    }),
  },
  mounted () {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
      window.addEventListener('popstate', this.goBack, false); // 回退时执行goback方法
    }
    this.isOver = true;
    // if(this.isVip === '普通用户' || !this.isVip){
    //   this.animation(0);
    // }else{
      this.startMatching();
    // }
  },
  beforeDestroy () {
    // 控制防止在等待动画的时候点击返回，然后过一段时间之后重新跳转回结果页面，因为跳转是放在宏任务里面的
    this.isOver = false;
    window.removeEventListener('popstate', this.goBack, false);
    clearInterval(this.timerOne);
    clearInterval(this.timerTwo);
    clearInterval(this.timerThree);
  },
  methods: {
    ...mapMutations('simpleMatching', [ 'setComponentName', 'setBasicDatas', 'setStars' ]),
    startMatching () {
      const obj = {
        analysisJump: true,
        entityName: this.companyName,
        memberId: this.userId,
        sysType: 1
      };
      getMatch({ ...obj }).then(res => {
        this.basicData = res.data.data.basicData;
        const index = 0;
        this.animation(index);
        this.setBasicDatas(res.data.data);
      }).catch(() => {
        setTimeout(() => {
          this.$message.error('链接超时，请重新匹配');
          this.setComponentName('Search');
        }, 5000);
      });
    },
    animation (index) {
      const len = this.list.length;
      if (index <= len - 1) {
        this.list[index].show = true;
        const subInd = 0;
        const subAnimation = (subInd, data) => {
          const subLen = data.length;
          this.timerOne = setTimeout(() => {
            if (subInd <= subLen - 1) {
              data[subInd].show = true;
              subInd += 1;
              subAnimation(subInd, data);
            } else {
              this.list[index].show = true;
              // this.list[index].status = subLen > 5 ? 2 : 1
              this.changeStatus(index);
              data.forEach(item => {
                item.show = false;
              });
              this.timerTwo = setTimeout(() => {
                index += 1;
                this.animation(index);
              }, 500);
            }
          }, 500);
        };
        subAnimation(subInd, this.list[index].children);
      } else {
        this.timerThree = setTimeout(() => {
          this.setComponentName('Search');
          if (this.isOver) this.$router.push('/simpleMatching/result');
          const arr = this.list.filter(item => item.status === 2);
          if (arr.length === 0) {
            this.setStars(3);
          } else if (arr.length <= 3) {
            this.setStars(2);
          } else {
            this.setStars(1);
          }
        }, 500);
      }
    },
    goBack () {
      if (this.$route.query.isRecord) {
        this.$router.push('/simpleMatching/record');
      } else {
        this.setComponentName('Search');
      }
    },
    changeStatus (index) {
      // if(this.isVip === '普通用户'){
      //   this.list.forEach(e=>e.status=1)
      //   return
      // }
      const {
        provCode, competent, education,
        enterpriseGroup, category, companyOrgType,
        businessProfile, intellectualProperty, certification
      } = this.basicData;
      let arr = [];
      switch (this.list[index].title) {
        case '基本情况':
          if (provCode === null || education === null || competent === null || enterpriseGroup === null || category === null || companyOrgType === null) {
            this.list[index].status = 2;
          } else {
            this.list[index].status = 1;
          }
          break;
        case '经营概况':
          arr = businessProfile.filter(item => item.isChecked === null);
          if (arr.length > 0) {
            this.list[index].status = 2;
          } else {
            this.list[index].status = 1;
          }
          break;
        case '知识产权':
          arr = intellectualProperty.filter(item => item.num === null);
          if (arr.length > 0) {
            this.list[index].status = 2;
          } else {
            this.list[index].status = 1;
          }
          break;
        case '资质认证':
          if (certification === null || certification.length <= 0) {
            this.list[index].status = 2;
          } else {
            this.list[index].status = 1;
          }
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
.main {
  // height: 400px;
  background-color: rgba(48, 120, 240, .05);
  margin: 39px 0 47px;
  padding: 39px 44px 47px;
}
.wating {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(196, 214, 234, 1);
  padding: 17px 37px 0 21px;
  display: flex;
  justify-content: space-between;
  .left {
    .left_title {
      font-size: 18px;
      display: flex;
      align-items: center;
      .left_icon {
        // color: #156ED0;
        // margin-right: 12px;
        margin-right: 8px;
        width: 25px;
        height: 25px;
        background-image: url("../../../../../assets/pc/images/simpleMatching/company.png");
        background-size: 25px 25px;
      }
    }
    .left_loading {
      width: 239px;
      height: 239px;
      margin: 19px 0 40px 123px;
      background-image: url("../../../../../assets/pc/images/simpleMatching/analysis.gif");
      background-size: 239px 239px;
      .loading_img {
        width: 239px;
        height: 239px;
        // background-color: #156ED0;
        background: radial-gradient(0.5000000000000001% 0.49999999999999994% at 50.5% 50.7%, rgba(188,214,253,0) 0%,rgba(48,120,240,0.1) 100%);
        text-align: center;
        line-height: 239px;
        border-radius: 50%;
        color: rgba(65, 96, 146, 1);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .right {
    margin-top: 10px;
    .right_header {
      color: rgba(97, 119, 154, 1);
      font-size: 16px;
      margin-bottom: 26px;
      margin-right: 153px;
      display: flex;
      align-items: center;
      .right_header_icon {
        width: 24px;
        height: 24px;
        margin: 0 6px 0 0;
        background-image: url("../../../../../assets/pc/images/simpleMatching/system_loading.png");
        background-size: 24px 24px;
      }
    }
    .right_content {
      height: 300px;
      background-image: url("../../../../../assets/pc/images/simpleMatching/basic_bg.png");
      background-size: 300px 280px;
      .content_ul {
        .content_li {
          margin-bottom: 15px;
          .sub_ul {
            color: rgb(159, 159, 159);
            font-size: 14px;
            margin-left: 12px;
            div {
              margin-top: 11px;
            }
          }
          .content_title {
            display: flex;
            // align-items: center;
            .title_circle {
              margin: auto;
              top: 50%;
              left: 50%;
              width: 6px;
              height: 6px;
              border: 1px solid rgba(0, 0, 0, 0.3);
              border-radius: 50%;
              margin-right: 6px;
            }
            .title_txt {
              font-size: 14px;
            }
            .title_line {
              width: 264px;
              // border-top: 1px dashed rgba(187, 187, 187, 1);
              background-image: linear-gradient(to right, rgba(187, 187, 187, 1) 35%, rgba(255,255,255,0) 0%); /* 35%设置虚线点x轴上的长度 */
              background-position: center; /* top配置上边框位置的虚线 */
              background-size: 10px 1px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
              background-repeat: repeat-x;
              margin: 0 10px;
            }
            .title_icon {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
ul, li, ol {
  // list-style: circle;
}
</style>
